/* Test.css */

.banner {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
    text-align: center;
  }
  
  .progress-bar {
    transition: width 0.6s ease; /* Smooth transition for progress bar */
    color: #fff; /* Text color inside the progress bar */
    text-align: center; /* Center text inside the progress bar */
    line-height: 1.5; /* Increase line height for better readability */
  }
  
  .shadow-lg {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .rounded {
    border-radius: 10px;
  }
  
  .rounded-circle {
    border-radius: 50%;
  }
  
  .bg-dark {
    background-color: #343a40 !important;
  }
  
  .text-light {
    color: #f8f9fa !important;
  }
  .list-container {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.list-scroll {
    max-height: 200px; /* Adjust the height as needed */
    overflow-y: auto;
}

.list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    color: #fff;
    height: auto;
}

.status-indicator {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 10px;
}
.booked-bg {
    background: linear-gradient(135deg, #ecf4f2, #ccf2ff, #b3ecff, #f0f0f0);
    color: #000; /* Optional: Adjust text color for better contrast */
}

.available-bg {
  background: linear-gradient(135deg, #99e6ff, #ccf2ff, #b3ecff, #e6f7ff);
  color: #000; /* Optional: Adjust text color for better contrast */
}


.list-item {
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px; /* Optional: Add some border radius for better appearance */
}
.list-scroll {
    overflow: auto; /* Allow scrolling */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
}
.list-container {
    position: relative;
    height: 260px;
}

.scroll-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background: linear-gradient(to right, #4e9af1, #78d0d3);
    z-index: 10;
    width: 0%; /* Will be updated dynamically */
}
