.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .loading-content {
    display: flex;
    align-items: center;
  }
  
  .spinner {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 80px; /* Adjusted size for better layout */
    height: 80px; /* Adjusted size for better layout */
    animation: spin 2s linear infinite;
  }
  
  .loading-text {
    margin-left: 20px; /* Space between spinner and text */
    color: white;
    font-size: 24px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  