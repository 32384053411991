*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}
a{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
#signinmodel{
    background-color:rgba(255, 255, 255, 0.5); /* Adjust the alpha value to control transparency */
    /* box-shadow: 10px 100px 10px  white; */
  }
  
  #signinmodellogin{
    background-color:rgba(240, 240, 240, 0.90); /* Adjust the alpha value to control transparency */
    /* box-shadow: 10px 100px 10px  white; */
  }
  
