.shop-create-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #87CEEB, #ffffff);
    z-index: -1;
}

.bubble {
    position: absolute;
    border-radius: 50%;
    background-color: #87CEEB;
    animation: move-bubble 10s infinite linear, change-size 10s infinite alternate;
}

@keyframes move-bubble {
    0% {
        transform: translateY(0) translateX(0);
    }
    50% {
        transform: translateY(-100%) translateX(100%);
    }
    100% {
        transform: translateY(0) translateX(0);
    }
}

@keyframes change-size {
    0% {
        width: 20px;
        height: 20px;
    }
    50% {
        width: 30px;
        height: 30px;
    }
    100% {
        width: 20px;
        height: 20px;
    }
}
